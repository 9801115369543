.claim-page {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  align-items:center;

  .card-container {
    position: relative;
    background: url("./assets/light-claim-container.png") no-repeat center;
    background-size: 100% 100%;
    width: 303px;
    height: 303px;
    margin-top:110px;
    display: flex;
    justify-content: center;
    align-items: center;

    .question-mark {
      position: relative;
      width: 190px;
      height: 190px;
    }
  }

  .card-container-dark-theme {
    background: url("./assets/dark-claim-container.png") no-repeat center;
    background-size: 100% 100%;
  }

  .input-container {
    position: relative;
    width: 228px;
    height: 44px;
    border: 0.5px solid #ADADAD;
    box-sizing: border-box;
    border-radius: 31px;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-top:40px;

    .input-code {
      border:0;
      outline: none;
      background-color:transparent;
      padding:0;
      width: 70px;
      height: 14px;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      text-align: justify;
      ::-webkit-input-placeholder{
        color: #CDCDCD;
      }
    }

    .input-code-dark-theme {
      color: #ffffff
    }
  }

  .claim-button {
    position: relative;
    width: 228px;
    height: 44px;
    box-sizing: border-box;
    border-radius: 31px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #FAFAFA;
    margin-top: 30px;
    margin-bottom: 95px;
  }
}

.claim-page-dark-theme {
  background: #1D1D1D;
}