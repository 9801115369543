.home-page {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .top-background-img{
    position: absolute;
    top: -40px;
    height: 482px;
    width: 170%;
    object-fit: cover;
    filter: blur(15px);
  }

  .top-container {
    position: relative;
    height: 490px;
    width: 100%;
    background: linear-gradient(0deg, #ffffff 0%, rgba(250, 250, 250, 0.76) 22%, rgba(248, 248, 249, 0.69) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      color: rgba(16, 16, 16, 0.8);
      max-width: 80%;
      display: flex;
      justify-content:center;
      align-items:center;
      height: 56px;
      margin-top: 55px;
    }

    .title-dark-theme {
      color: rgba(255, 255, 255, 0.8);
    }

    .home-swiper {
      height:370px;
    }

    .nft-card {
      position: relative;
      width: 295px;
      height: 345px;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      overflow:hidden;
      background-color:#ffffff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    }

    .nft-image {
      width: 100%;
    }

    .nft-author-container {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 8px;

      .avatar {
        position: relative;
        width: 32px;
        height: 32px;
        margin-left:9px;
        margin-right: 10px;
      }

      .author-name {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #0A0E2E;
      }
    }
  }

  .top-container-dark-theme {
    background: linear-gradient(0deg, #1D1D1D 0%, rgba(0, 0, 0, 0.76) 22%, rgba(0, 0, 0, 0.69) 100%);
  }

  .retrieve-button {
    width: 228px;
    height: 44px;
    border-radius: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: justify;
    color: #FAFAFA;
    display: flex;
    align-items:center;
    justify-content: center;
    margin-top:30px;
    margin-bottom: 95px;
  }

  .desc-container {
    margin-top:20px
  }
}


.home-page-dark-theme {
  background: #1D1D1D;
}