.login-background {
  position: relative;
  width: 100vw;
  min-height:100vh;

  .background {
    background: url("./assets/background-image.png") no-repeat center;
    background-size: 100% 100%;
    position: relative;
    width: 100vw;
    height:0;
    padding-bottom: 195%;
  }
}

.login {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 0 auto;
  background-size: cover;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99;

  input {
    background: none;
  }

  .title-container{
    position: relative;
    width: 100%;
    height: 85px;
    padding-top: 97px;

    padding-bottom: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    img{
      height: 45px;
    }
  }

  .input-container{
    width: 335px;
    height: 50px;
    display: flex;
    justify-content: space-between; //验证码在右边
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 20px;
    border-bottom: 0.5px solid rgba(70, 70, 70, 0.2);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
    ::-webkit-input-placeholder{
      color: rgba(16, 16, 16, 0.5);
    }

    button{
      font-size: 14px;
    }

    input{
      border:none;
      padding: 0 0 0 0;
      font-size: 14px;
      outline: none;
    }

    .phone{
      width: 100%;
      height: 100%;
    }

    .input-wrapper .code{
      width: 180px;
      height:100%;
      margin-right: 18px;
    }

    .code-button{
      width: 100px;
      border:none;
      padding: 0;
      text-align: right;
    }

    .code-button:disabled{
      color: white;
    }


    .full-length-button{
      width: 100%;
      height: 100%;
      border-radius: 30px;
      outline: none;
      box-shadow: none;
      font-weight: 500;
      border: none;
    }
  }

  .agreement-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    .agreement-label{
      display: flex;
      align-items: center;
      margin-left: 7px;
    }

  }

  .theme-color{
    font-size: 14px;
  }
}

.login-dark-theme {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.76) 0%, rgba(0, 0, 0, 0.69) 100%);
  z-index: 99;

  .input-container {
    border-bottom: 0.5px solid rgba(199, 199, 199, 0.2);
    ::-webkit-input-placeholder{
      color: #C7C7C7
    }
  }
  input {
    color:#C7C7C7;
  }
}

.login-light-theme {
  background: linear-gradient(0deg, rgba(250, 250, 250, 0.6) 0%, rgba(248, 248, 249, 0.6) 100%);
  z-index: 99;

  input {
    color: rgba(16, 16, 16, 0.5);
  }
}
