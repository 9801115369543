.footer-warning {
  position: absolute;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 10px;

  .warning-text {
    width: 80%;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    color: #6F6F6F;
    opacity: 0.5;
  }

  .warning-text-dark-theme {
    color: #FFFFFF;
  }
}