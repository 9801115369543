.congrats-page {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .nft-background-image {
    position: absolute;
    top:0;
    width: 100%;
    object-fit: cover;
    z-index: 0;
    filter: blur(20px);
  }

  .nft-container {
    position: relative;
    height:480px;
    width: 100%;
    background: linear-gradient(0deg, #ffffff 0%, rgba(250, 250, 250, 0.76) 22%, rgba(248, 248, 249, 0.69) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    margin-bottom: 5px;

    .nft-background-light {
      position:absolute;
      width: 100vw;
      top:46px;
    }

    .nft-title {
      position: relative;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 31px;
      text-align: center;
      letter-spacing: 1px;
      color: rgba(16, 16, 16, 0.8);
      text-shadow: 0px 0px 6px rgba(255, 231, 174, 0.4);
      margin-top:40px;
    }

    .nft-title-dark-theme {
      color: #FFFFFF
    }

    .nft-id {
      position: relative;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 1px;
      color: rgba(16, 16, 16, 0.6);
      margin-top:7px
    }

    .nft-id-dark-theme {
      color: rgba(228, 228, 228, 0.8);
    }

    .nft-image {
      position: relative;
      width:300px;
      height:300px;
      border-radius: 12px;
      margin-top: 26px;
    }
  }

  .nft-container-dark-theme {
    background: linear-gradient(0deg, #1D1D1D 0%, rgba(0, 0, 0, 0.76) 22%, rgba(0, 0, 0, 0.69) 100%);
  }

  .confirm-button {
    position: relative;
    width: 228px;
    height: 44px;
    box-sizing: border-box;
    border-radius: 31px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #FAFAFA;
    margin-top: 30px;
    margin-bottom: 100px;
  }
}

.congrats-page-dark-theme {
  background: #1D1D1D;
}