#about-container{
  position: relative;
  min-height: 100vh;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.22) 69.95%);

  #about-content{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    width: 85%;
    margin-top: 50px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;  
    text-align: justify;
    z-index: 20;

    #about-title{
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 30px;
      text-align: justify;
    }

    .lineBetween{
      margin: 35px 0px 50px 0px;
    }

    .lineBetween:last-child {
      margin-bottom:10px;
    }
  }

  .about-page-ornament1 {
    position: absolute;
    top:33px;
    right:0;
    height: 349px;
    width:258px;
    z-index:10
  }

  .about-page-ornament2 {
    position: absolute;
    top:-82px;
    right:0;
    height: 262px;
    width:180px;
    z-index: 10;
  }
}