
.App {
  text-align: center;
  overflow-x: hidden;
  width: 100vw;
  min-height: 100vh;
  position: absolute;
}

button {
  border: 0;
  outline: none;
  background-color:transparent;
  padding:0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
